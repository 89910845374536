<template>
  <b-container fluid class="mb-6 mt-6">
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="3">
          <b-card class="h-100">
            <template v-if="detail">
              <h6 class="mb-6">{{ detail.name }}</h6>
              <div class="d-flex mb-6">
                <b-button
                  v-if="isWritePermission"
                  size="sm"
                  class="btn-edit mr-4"
                  @click="edit"
                >
                  <span class="svg-icon svg-icon-sm mr-0">
                    <inline-svg
                      class="svg-icon mr-0"
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </b-button>
                <b-button
                  pill
                  size="sm"
                  class="btn-status px-7"
                  :class="getStatus(detail.status)"
                  >{{ getStatus(detail.status) }}</b-button
                >
              </div>
              <div class="mb-6">
                <label for="" class="text-gray">Mã Câu hỏi</label>
                <div>{{ detail.code }}</div>
              </div>
              <div class="mb-6">
                <label for="" class="text-gray">Số lượng câu hỏi</label>
                <div>
                  <span class="svg-icon svg-icon-xl">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/Library.svg"
                    />
                  </span>
                  {{ detail.questionCount }} câu hỏi
                </div>
              </div>
              <div class="mb-6">
                <label for="" class="text-gray">Cập nhật lần cuối</label>
                <div>{{ formatDate(detail.updateDate) }}</div>
              </div>
              <div>
                <label for="" class="text-gray">Người cập nhật</label>
                <div class="d-flex align-items-center">
                  <avatar
                    size="40px"
                    :text="detail.updaterName"
                    :src="detail.updaterImage.url"
                    :rounded="true"
                  />
                  <div class="d-flex flex-column ml-5">
                    <p class="mb-0 font-weight-bolder">
                      {{ detail.updaterName }}
                    </p>
                    <p class="mb-0 text-gray">{{ detail.updaterName }}</p>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="h-100"
            header="Danh mục câu hỏi"
            header-class="text-success font-weight-bolder font-size-h5 p-6"
          >
            <template v-for="question in questions">
              <Question
                :key="question.id"
                :id="question.id"
                :question="question"
                is-detail
                @view="viewQuestion"
              />
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <ModalQuestion
      v-if="showModal"
      :id="id"
      :type="typeModal"
      :question="questionDetail"
      @reset="resetModal"
    />
  </b-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  GET_QUESTION_GROUP_DETAIL,
  RESET_STATE_SURVEY,
} from '@/core/services/store/survey.module';
import { MODAL_TYPE } from '@/core/plugins/constants';
const { mapActions, mapMutations, mapState } =
  createNamespacedHelpers('survey');
export default {
  name: 'SurveyQuizeDetail',
  components: {
    Question: () => import('./components/Question.vue'),
    ModalQuestion: () => import('./components/ModalQuestion.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      showModal: false,
      detail: null,
      questionDetail: null,
      typeModal: null,
    };
  },
  computed: {
    ...mapState(['questions']),
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.getQuestionGroup();
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  methods: {
    ...mapActions({ GET_QUESTION_GROUP_DETAIL }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    async getQuestionGroup() {
      this.loading = true;
      const { error, data } = await this.GET_QUESTION_GROUP_DETAIL(this.id);
      if (!error) {
        this.detail = data;
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loading = false;
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';

        default:
          return 'draft';
      }
    },
    edit() {
      this.$router.push({
        name: 'survey_quize_edit',
        params: {
          id: this.id,
        },
      });
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    resetModal() {
      this.typeModal = null;
      this.showModal = false;
      this.questionDetail = null;
    },
    viewQuestion(item) {
      this.typeModal = MODAL_TYPE.DETAIL;
      this.questionDetail = item;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-gray {
  color: #b5b5c3;
}
.wrap-questions {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
</style>
<style lang="scss">
.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
.btn-status {
  text-transform: capitalize;
  color: #fff !important;

  &.active {
    background-color: #21a567 !important;
    border-color: #21a567 !important;
  }

  &.inactive {
    background-color: #ff5756 !important;
    border-color: #ff5756 !important;
  }

  &.draft {
    background-color: #888c9f !important;
    border-color: #888c9f !important;
  }
}
</style>
